<template>
  <div class="navbar">
    <div class="nav">
      <el-menu
        mode="horizontal"
        :default-active="$route.path"
        :unique-opened="true"
        :router="true"
        text-color="#69696A"
        active-text-color="#3978E7"
      >
        <el-menu-item index="/member" v-if="Type==0 && PurchaseId==0">
          成员维护
        </el-menu-item>
        <el-menu-item index="/jurisdiction">
          角色权限维护
        </el-menu-item>
        <el-menu-item index="/procure" v-if="Type==0 && PurchaseId==0">
          采购组织维护
        </el-menu-item>
        <el-menu-item index="/hosptail" v-if="Type==0 && PurchaseId==0">
              医院维护
        </el-menu-item>
        <el-menu-item index="/Accesslog" v-if="Type==0 && PurchaseId==0">
              访问日志
        </el-menu-item>
      </el-menu>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default { 
  data() {
    return {
      PurchaseId: window.sessionStorage.getItem('PurchaseId'),
      Type: window.sessionStorage.getItem('Type'),
    };
  },
  methods: {
  },
};
</script>
<style lang='scss' scoped>
.navbar {
  background-color: white;
  .nav {
    margin: 10px 20px 0;
    .el-menu {
      background-color: transparent;
      border: 0;
      .el-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
      }
    }
  }
}
</style>
